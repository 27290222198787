import { produce } from 'immer';
import localStorageKeys from 'constants/localStorageKeys';
import * as types from './commonTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    isParticipantView:
        localStorage?.getItem(localStorageKeys.PARTICIPANT_VIEW) === 'true',
    dataTagsWithValues: [],
    mainDataTags: [],
    dataTagsOptions: [],
    attendeeFeedbackModal: false,
    impersonatedUser:
        localStorage.getItem(localStorageKeys.IMPERSONATED_USER) || null,
};

const actionHandlers = {
    [types.IS_PARTICIPANT_VIEW]: produce((draft, { isParticipantView }) => {
        draft.isParticipantView = isParticipantView;
    }),
    [types.DATA_TAGS_VALUES]: produce((draft, { dataTagsWithValues }) => {
        draft.dataTagsWithValues = dataTagsWithValues;
    }),
    [types.DATA_TAGS_OPTIONS]: produce((draft, { dataTagsOptions }) => {
        draft.dataTagsOptions = dataTagsOptions;
    }),
    [types.MAIN_DATA_TAGS_OPTIONS]: produce((draft, { mainDataTags }) => {
        draft.mainDataTags = mainDataTags;
    }),
    [types.ATTENDEE_FEEDBACK_MODAL]: produce(
        (draft, { attendeeFeedbackModal }) => {
            draft.attendeeFeedbackModal = attendeeFeedbackModal;
        }
    ),
    [types.UPDATE_IMPERSONATED_USER]: produce((draft, { user }) => {
        draft.impersonatedUser = user;
        localStorage.setItem(localStorageKeys.IMPERSONATED_USER, user || null);
    }),
};

export default createReducer(initialState, actionHandlers);
