const apiRoutes = {
    auth: 'auth',
    register: 'users/register',
    verifyEmail: 'users/verify-email',
    eventParticipants: 'events/:eventId/participants',
    createParticipant: 'events/:eventId/participants',
    checkParticipant: 'events/:eventId/participants/check-user',
    mapParticipant: 'events/:eventId/participants/map-user',
    mapParticipantToEvent:
        'events/:eventId/participants/map-participant-to-event',
    updateParticipant: 'events/:eventId/participants/:participantId',
    getParticipantById: 'events/:eventId/participants/:participantId',
    profilePhoto: 'events/:eventId/participants/:participantId/photo',
    bulkUpdateParticipants: 'events/:eventId/participants',
    getParticipantFilters: 'events/:eventId/participants/filters',
    getParticipantUpdateOptionsByEvent:
        'events/:eventId/participants/update-options',
    getParticipantEvents: 'users/:userId/events',
    getEventSessions: 'events/:eventId/sessions',
    getSessionById: 'events/:eventId/sessions/:sessionId',
    getSessionViewById: 'events/:eventId/sessions/v2/:sessionId/view',
    updateSessionById: 'events/:eventId/sessions/:sessionId',
    bulkUpdateSessions: 'events/:eventId/sessions',
    deleteSession: 'events/:eventId/sessions',
    enrollSession: 'events/:eventId/sessions/:sessionId/enroll',
    oneTimeModerator: 'events/:eventId/sessions/:sessionId/invite-moderator',
    getTargetAudienceUpdateOptionsByEvent:
        'events/:eventId/target-audiences/update-options',
    resetPasswordRequest: 'auth/reset-password/request',
    resetPasswordComplete: '/auth/reset-password/complete',
    getSessionUpdateOptionsByEvent: 'events/:eventId/sessions/update-options',
    getEnrollableUsersByEvent:
        'events/:eventId/sessions/:sessionId/enrollable-users',
    checkTargetAudienceByEvent: 'events/:eventId/target-audiences/check',
    checkEnrolledAudienceByEvent: 'events/:eventId/sessions/:sessionId/check',
    eventSessions: 'events/:eventId/sessions',
    eventAccessReport: 'events/:eventId/participants/extra-reports',
    attendeesReport: 'events/attendees-report',
    attendeesFeedbackReport: 'event-hubs/feedbacks',
    getSessionFilters: 'events/:eventId/sessions/filters',
    participantSessions: 'events/:eventId/sessions/v2/view',
    scheduledMeetings:
        'events/:eventId/meetings/:meetingId/intervals/:intervalId/participants',
    upcomingSessions: 'events/:eventId/sessions/v2/upcoming-sessions',
    getViewSessionFilters: 'events/:eventId/sessions/v2/view-filters',
    createSession: 'events/:eventId/sessions',
    moderationSessions: 'sessions',
    getModerationDetailsById: 'sessions/:sessionId/instances',
    getModeratorQA:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/answers',
    getModeratorQAByModerationMagicLink: 'moderators/answers',
    updateInstance: 'engagement-tools/:engagementToolId/instances/:instanceId',
    updateModerationStatus:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/status',
    getInstanceDetailsById:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId',
    getInstanceDetailsByModerationMagicLink: 'moderators',
    getQuizStatusById:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/answers/quiz/status',
    getInstanceDetailsForViewById:
        'engagement-tools/:engagementToolId/instances/view/:engagementToolInstanceId',
    updateModerationQAColumnValue:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/answers/:answerId/moderate',
    updateModerationQAColumnValueByModerationMagicLink:
        'moderators/answers/:answerId/moderate',
    updateModerationQAReplyColumnValue:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/reply/:answerId',
    updateModerationQAReplyColumnValueByModerationMagicLink:
        '/moderators/reply/:answerId',
    getSurveyOrPollResult:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/answers/results',
    getMagicLinkTokenForModeration:
        'engagement-tools/:engagementToolId/instances/:engagementToolInstanceId/magic-link-token',
    eventParticipantsForView: 'events/:eventId/participants/view',
    getDirectoryForView: 'events/:eventId/pages/view/:pageId/directory',
    getDirectoryForPreview: 'events/:eventId/pages/directory-preview',
    getDirectoryDetailForView:
        'events/:eventId/pages/view/:pageId/directory/:userId',
    getDirectoryFiltersForView:
        'events/:eventId/pages/view/:pageId/directory/filters',
    getSpeakerDirectoryDetailForView:
        'events/:eventId/sessions/v2/:sessionId/view/speaker/:userId',
    getUserDetailForView: 'events/:eventId/participants/:userId/profile',
    impersonateUserList: 'events/:eventId/participants/impersonate-user-list',
};

export default apiRoutes;
